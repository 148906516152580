import {
  ALERT_EDITOR,
  CREATE_ADDON_GIFT,
  CTA_EDITOR,
  EXTENDED_FIELDS,
  FAMILY_RELATIONSHIP_PICKER,
  FNC_UPGRADE,
  GIFT_REFUND,
  MANAGE_PROXY_CONTACTS,
  SACRAMENT_TRACKING,
  STAX_KYC,
  TWO_FA_DISENROLLMENT,
  TWO_FA_ENROLLMENT,
} from "./contentKeys";

const configsByContentKey = {
  flexHeight: {
    [CREATE_ADDON_GIFT]: true,
    [CTA_EDITOR]: true,
    [GIFT_REFUND]: true,
    [FNC_UPGRADE]: true,
    [SACRAMENT_TRACKING]: true,
    [MANAGE_PROXY_CONTACTS]: true,
    [FAMILY_RELATIONSHIP_PICKER]: true,
  },
  flexWidth: {
    [CTA_EDITOR]: true,
    [GIFT_REFUND]: true,
    [STAX_KYC]: true,
    [FNC_UPGRADE]: true,
    [FAMILY_RELATIONSHIP_PICKER]: true,
  },
  maxWidth: {
    [CREATE_ADDON_GIFT]: "500px",
    [SACRAMENT_TRACKING]: "1000px",
    [TWO_FA_ENROLLMENT]: "550px",
    [TWO_FA_DISENROLLMENT]: "500px",
  },
  maxHeight: {
    [STAX_KYC]: "600px",
    [TWO_FA_ENROLLMENT]: "450px",
    [TWO_FA_DISENROLLMENT]: "400px",
  },
  minHeight: {
    [SACRAMENT_TRACKING]: "600px",
  },
  legacy: {
    [CREATE_ADDON_GIFT]: true,
    [CTA_EDITOR]: true,
    [GIFT_REFUND]: true,
    [EXTENDED_FIELDS]: true,
    [ALERT_EDITOR]: true,
  },
  closeButtonPosition: {},
};

export default configsByContentKey;
