import FnrFetch3 from "Utilities/FnrFetch/FnrFetch3";
import { fnThrowError } from "Utilities/fnReportError";

const flocknoteNetworkApi = {
  getNetworkMetadata: () => new FnrFetch3(`/network/getMetadata`),
  getUserData: () => new FnrFetch3(`/user/userData`),
  getUserFundItPermissions: () => new FnrFetch3(`/user/fundItPermissions`),
  getFundItStatus: () => new FnrFetch3(`/fundit/getFundItStatus`),
  staxStatus: () => new FnrFetch3("/network/staxStatus"),
};

export const getNetworkMetadata = async () => {
  const endpoint = `/network/getMetadata`;

  const response = await new FnrFetch3(endpoint).post().catch((err) =>
    fnThrowError({
      message: `Error at ${endpoint}: ${err}`,
    })
  );
  window.fnReactBus.setMetaData(response);
  return response;
};

export const getUserData = async () => {
  const endpoint = `/user/userData`;

  const response = await new FnrFetch3(endpoint).get().catch((err) =>
    fnThrowError({
      message: `Error at ${endpoint}: ${err}`,
    })
  );

  return response;
};

export const getUserFundItPermissions = async () => {
  const endpoint = `/user/fundItPermissions`;

  const response = await new FnrFetch3(endpoint).get().catch((err) =>
    fnThrowError({
      message: `Error at ${endpoint}: ${err}`,
    })
  );

  return response;
};

export const getFundItStatus = async () => {
  const endpoint = `/fundit/getFundItStatus`;

  const response = await new FnrFetch3(endpoint).get().catch((err) =>
    fnThrowError({
      message: `Error at ${endpoint}: ${err}`,
    })
  );

  return response;
};

export const getStaxStatus = async () => {
  const endpoint = `/network/staxStatus`;

  const response = await new FnrFetch3(endpoint).get().catch((err) =>
    fnThrowError({
      message: `Error at ${endpoint}: ${err}`,
    })
  );

  return response;
};

export default flocknoteNetworkApi;
